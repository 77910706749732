import React, { useState, useEffect } from "react";

import { CustomText, Video } from "../components";
import Core from "../common/clientSdk";
import { Loader } from "../screens/loader";
import Utils from "../common/utils";
import Icon from "../components/core_component/icon";
import { useSnackbar } from "react-simple-snackbar";
import { Image } from "../components";

import { Rating } from "react-simple-star-rating";

import * as CampaignData from "../common/data/campaign";
import Carousel from "react-multi-carousel";
import { toast } from "react-toastify";
import Lottie from "react-lottie";
import * as LoadingUI from "../resources/data/loading.json";
import { useSelector } from "react-redux";
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingUI,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};
function InfluencerDetailModal({
  influencerFlyOut,
  toggleFlyoutDropdown,
  influencer_id,
}) {
  const [influencerData, setInfluencerData] = useState();
  const [categoryListData, setCategoryListData] = useState([]);
  const [platformsData, setPlatformsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [portfolio, setPortfolio] = useState([]);
  const [showVideo, setShowVideo] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [portfolioDataSava, setPortfolioDataSava] = useState({});
  const [effectiveFollowers, setEffectiveFollowers] = useState(null);
  const [checkOrgSub, setCheckOrgSub] = useState({});
  const [effectiveLoad, setEffectiveLoad] = useState(false);
  const [openSnackbar] = useSnackbar();

  useEffect(() => {
    getInfluencerDetailPublic(updateProgress);
    userSelfData();
  }, []);

  useEffect(() => {
    if (
      influencerData &&
      !effectiveFollowers &&
      checkOrgSub?.feature?.max_brand_count > 1
    ) {
      getEffectiveFollowers();
    }
  }, [influencerData, checkOrgSub]);

  function updateProgress(status) {
    setLoading(status);
  }

  console.log("hhhhhh", checkOrgSub?.feature?.max_brand_count > 1);

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;

  function userSelfData() {
    var params = { org_id: brand_deep_data?.brand_data?.org_id };

    Core.userSelfData(
      userSelfDataSuccess,
      userSelfDataFailed,
      () => {},
      params
    );
  }

  function userSelfDataSuccess(response) {
    setCheckOrgSub(response?.org_data?.subscription_data);
  }
  function userSelfDataFailed() {}

  function getEffectiveFollowers() {
    var params = {
      influencer_id: influencer_id,
      platform_id: "btll",
    };

    Core.getEffectiveFollowers(
      getEffectiveFollowersSuccess,
      getEffectiveFollowersFail,
      (status) => setEffectiveLoad(status),
      params
    );
  }

  function getEffectiveFollowersSuccess(response) {
    setEffectiveFollowers(response.effective_followers);
  }

  function getEffectiveFollowersFail(error) {
    toast.error(error.map((item) => item.m).join(""));
  }
  function influencerPortfolio() {
    var params = { influencer_id: influencer_id };
    Core.influencerPortfolio(
      influencerPortfolioSuccess,
      influencerPortfolioFailed,
      () => {},
      params
    );
  }

  function influencerPortfolioSuccess(response) {
    setPortfolio(response["portfolio_list"]);
  }

  function influencerPortfolioFailed() {}

  function getInfluencerDetailPublic(progress_cb) {
    var params = { influencer_id: influencer_id };
    Core.getInfluencerDetailPublic(
      getInfluencerDetailPublicSuccess,
      getInfluencerDetailPublicFailed,
      progress_cb,
      params
    );
  }

  function getInfluencerDetailPublicSuccess(response) {
    setInfluencerData(response.influencer_deep_data.influencer_data);
    setPlatformsData(response.influencer_deep_data.platforms_data);
    setCategoryListData(
      Object.values(response.influencer_deep_data.categorys_data)
    );

    setLoading(false);
  }

  function getInfluencerDetailPublicFailed(errorList) {
    openSnackbar(errorList.map((item) => item.m).join(", "));
  }

  let influencerDp = influencerData?.profile_picture
    ? Utils.generateInfluencerDpUrl(
        influencerData?.influencer_id,
        influencerData?.profile_picture
      )
    : require("../resources/images/user-copy.png");

  function getInfluencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]["n"];
    } else {
      return "-";
    }
  }

  const getSelectedPlatformInstagramData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "btll");
  };

  const formatPlatformInstagramData = (platforms_data, key) => {
    const selectedPlatformData =
      getSelectedPlatformInstagramData(platforms_data);
    return Number(Utils.changeNumberFormate(selectedPlatformData?.[key]));
  };

  const getEngagementInstagramRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };
  const getSelectedPlatformYoutubeData = (platforms_data) => {
    return platforms_data?.find((item) => item.platform_id === "nxjo");
  };

  const formatPlatformYoutubeData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformYoutubeData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const getEngagementYoutubeRate = (avg_comments, avg_likes, avg_views) => {
    var eng_data = Number(avg_likes) + Number(avg_comments);

    var eng_rate = (eng_data / Number(avg_views)) * 100;
    return eng_rate.toFixed(1);
  };

  function influencerDetails() {
    return (
      <div className="influencer_detail_main_modal_container">
        <div className="influencer_modal_detail_container p-b-20">
          <Image
            fallBack={require("../resources/images/user-copy.png")}
            source={influencerDp}
            className="influencer-user-modal-profile"
          />
          <div className="influencer-details-section">
            <CustomText
              h5
              text={influencerData?.name}
              fontSize={"font_size_2xl"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
            />
            <div className="flex-row">
              {" "}
              <Rating
                initialValue={influencerData?.ratings}
                size={20}
                readonly={true}
              />
              <div className="m-l-8"></div>
            </div>

            {/* <CustomText
              p
              text={influencerData?.note}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_regular_small"}
            /> */}
          </div>
        </div>
        <div className="influencer_address_detail_container">
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Location"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData && getInfluencerLocation(influencerData)}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail m-b-16">
            <CustomText
              p
              text={"Gender"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.gender}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
          <div className="influencer_address_detail">
            <CustomText
              p
              text={"Languages"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"rubik_regular"}
              height={"line_height_19_2"}
            />
            <CustomText
              p
              text={influencerData?.language}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Inter_medium_500"}
              height={"line_height_19_2"}
            />
          </div>
        </div>
        <div className="influencer-detail-category-modal-platform">
          <div class="">
            <CustomText
              p
              text={"Category"}
              fontSize={"font_size_large"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
            />
            <div className="category-background-influencer-container">
              {categoryListData &&
                categoryListData.map((item, index) => {
                  return (
                    <>
                      {" "}
                      <div className="category-background-modal-influencer">
                        <CustomText
                          p
                          text={item.name}
                          fontSize={"font_size_large"}
                          textColor={"text_color_primary"}
                          fontWeight={"rubik_regular"}
                        />
                      </div>{" "}
                      <div
                        style={{ color: "#8E8E93", fontSize: "large" }}
                        className="m-t-16 m-r-6"
                      >
                        {index < categoryListData.length - 1 ? "|" : ""}
                      </div>
                    </>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  const handleVideoClick = () => {
    setShowVideo(!showVideo);
  };

  let imgSrc =
    portfolioDataSava.link +
    "/" +
    "portfolio_content" +
    "/" +
    portfolioDataSava.influencer_id +
    "/" +
    portfolioDataSava.media_id;

  function recentCollaboration() {
    return (
      <>
        <div>
          <div className="m-b-15">
            <CustomText
              h5
              text={"Social Analytics"}
              fontSize={"font_size_2xl"}
              textColor={"text_color_primary"}
              fontWeight={"Poppins_regular_large"}
              textAlign={"text_align_left"}
            />
          </div>
          {platformsData.map((item) => {
            if (item.platform_id === "btll" && effectiveFollowers === null) {
              setEffectiveFollowers(item.effective_followers);
            }
            if (item.platform_id == "btll") {
              return (
                <div className="engagement-view-container">
                  <div className="engagement-view-inner ">
                    <div className="instagram-data-view">
                      <Icon iconName="reelsIconInstagram" />
                      <CustomText p text={"Instagram"} className="button2" />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Followers"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.followers)
                            ? "-"
                            : Utils.changeNumberFormate(item.followers)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Views"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_views)
                            ? "-"
                            : Utils.changeNumberFormate(item.avg_views)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"Avg. Likes"} className="caption_2" />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_likes)
                            ? "-"
                            : Utils.changeNumberFormate(item.avg_likes)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText
                        p
                        text={"Avg. Comments"}
                        className="caption_2"
                      />
                      <CustomText
                        p
                        text={
                          Utils.isNullOrUndefinedOrZero(item.avg_comments)
                            ? "-"
                            : Utils.changeNumberFormate(item.avg_comments)
                        }
                        className="button2"
                      />
                    </div>
                    <div className="instagram-data-view">
                      <CustomText p text={"E.R."} className="caption_2" />
                      <CustomText
                        p
                        text={
                          !Utils.isNullOrUndefinedOrZero(item.engagement_rate)
                            ? item.engagement_rate.toFixed(2) + " %"
                            : "-"
                        }
                        className="button2"
                      />
                    </div>

                    <div className="instagram-data-view">
                      <CustomText p text={"E.F."} className="caption_2" />
                      {effectiveLoad ? (
                        <Lottie
                          options={defaultOptions}
                          height={15}
                          width={100}
                        />
                      ) : (
                        <CustomText
                          p
                          text={
                            effectiveFollowers
                              ? effectiveFollowers.toFixed(2) + " %"
                              : "NA"
                          }
                          className={
                            checkOrgSub?.feature?.max_brand_count > 1 &&
                            effectiveFollowers
                              ? "button2"
                              : "blur-text"
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              );
            } else {
              return (
                <>
                  <div>
                    <div className="engagement-view-container">
                      <div className="engagement-view-inner">
                        <div className="instagram-data-view">
                          <Icon iconName="youtubeNewIcon" />
                          <CustomText p text={"Youtube"} className="button2" />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Views"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_views)
                                ? "-"
                                : Utils.changeNumberFormate(item.avg_views)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Likes"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_likes)
                                ? "-"
                                : Utils.changeNumberFormate(item.avg_likes)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText
                            p
                            text={"Avg. Comments"}
                            className="caption_2"
                          />
                          <CustomText
                            p
                            text={
                              Utils.isNullOrUndefinedOrZero(item.avg_comments)
                                ? "-"
                                : Utils.changeNumberFormate(item.avg_comments)
                            }
                            className="button2"
                          />
                        </div>
                        <div className="instagram-data-view">
                          <CustomText p text={"E.R."} className="caption_2" />
                          <CustomText
                            p
                            text={
                              !Utils.isNullOrUndefinedOrZero(
                                item.engagement_rate
                              )
                                ? item.engagement_rate + " %"
                                : "-"
                            }
                            className="button2"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            }
          })}
        </div>

        {portfolio && portfolio?.length !== 0 && (
          <div className="recent_collaboration_main_container">
            <div className="m-b-15">
              <CustomText
                h5
                text={"Portfolio"}
                fontSize={"font_size_2xl"}
                textColor={"text_color_primary"}
                fontWeight={"Poppins_regular_large"}
              />
            </div>
            <div className="all_post_image_container">
              <Carousel
                additionalTransfrom={0}
                arrows
                autoPlaySpeed={5000}
                centerMode={false}
                className="carousel"
                containerClass="container"
                draggable
                focusOnSelect={false}
                itemClass="carousel-item"
                keyBoardControl
                minimumTouchDrag={20}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 2000,
                      min: 1024,
                    },
                    items: 3,
                    partialVisibilityGutter: 40,
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0,
                    },
                    items: 1,
                    partialVisibilityGutter: 30,
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464,
                    },
                    items: 2,
                    partialVisibilityGutter: 30,
                  },
                }}
                sliderClass=""
                slidesToSlide={1}
              >
                {portfolio &&
                  portfolio?.map((items, index) => {
                    let thumbSrc =
                      items.link +
                      "/" +
                      "portfolio_thumbnail" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.thumbnail_id;

                    let imgSrc =
                      items.link +
                      "/" +
                      "portfolio_content" +
                      "/" +
                      items.influencer_id +
                      "/" +
                      items.media_id;
                    if (items.item_type == "IMAGE") {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick(index);
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container"
                        >
                          <Image
                            fallBack={require("../resources/images/influencer-post-fall-back.png")}
                            source={imgSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div></div>
                        </div>
                      );
                    } else {
                      return (
                        <div
                          onClick={() => {
                            handleVideoClick();
                            setPortfolioDataSava(items);
                          }}
                          className="post_image_container"
                        >
                          <Image
                            fallBack={require("../resources/images/influencer-post-fall-back.png")}
                            source={thumbSrc}
                            alt={"influencer post"}
                            className="post_image"
                          />

                          <div></div>
                        </div>
                      );
                    }
                  })}
              </Carousel>
              <div>
                <span>
                  <span>
                    {portfolioDataSava.item_type == "IMAGE" && (
                      <>
                        {showVideo && (
                          <>
                            <div className="fullscreen-image">
                              <div style={{ position: "relative" }}>
                                <div
                                  onClick={() => handleVideoClick()}
                                  className="fullscreen-image-close-btn"
                                >
                                  <Icon iconName="CloseRoundButtonIcon" />
                                </div>
                                <Image
                                  fallBack={require("../resources/images/influencer-post-fall-back.png")}
                                  source={imgSrc}
                                  alt={"influencer post"}
                                  width={600}
                                  height={337}
                                />
                              </div>
                            </div>
                          </>
                        )}
                      </>
                    )}
                  </span>
                  {portfolioDataSava.item_type !== "IMAGE" && (
                    <>
                      {showVideo && (
                        <>
                          <div className="fullscreen-image">
                            <div style={{ position: "relative" }}>
                              <div
                                onClick={() => handleVideoClick()}
                                className="fullscreen-image-close-btn"
                              >
                                <Icon iconName="CloseRoundButtonIcon" />
                              </div>
                              <Video
                                source={imgSrc}
                                type={"video/mp4"}
                                width={600}
                                height={337}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </>
                  )}
                </span>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
  console.log("checkOrgSub", checkOrgSub);

  return (
    <>
      <div
        tabIndex={0}
        onClick={toggleFlyoutDropdown}
        className={`sidebar ${influencerFlyOut ? "open" : ""}`}
      >
        <div
          className="sidebar-flyout-content-modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="internal-modal-page-container-modal">
            <div className="payment-summary-heading-modal">
              <CustomText h5 text={"Influencer Details"} className="h6Text" />
              {/* <button onClick={toggleFlyoutDropdown}>Close</button> */}
              <div
                className="cursor-pointer m-r-10"
                onClick={toggleFlyoutDropdown}
              >
                <Icon iconName="CrossBtnIcon" />
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <div>
                <div>{influencerDetails()}</div>
                {platformsData.length != 0 && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 10,
                      padding: "0px 20px",
                    }}
                  >
                    <div> {recentCollaboration()}</div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InfluencerDetailModal;
