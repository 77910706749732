import React, { useEffect, useState } from "react";
import { useSnackbar } from "react-simple-snackbar";
import {
  CustomText,
  Image,
  CustomButton,
  PrimaryCta,
  FallBack,
  SubscriptionBuyButton,
} from "../../components";
import Core from "../../common/clientSdk";
import Utils from "../../common/utils";
import { Loader } from "../loader";
import Icon from "../../components/core_component/icon";
//import { Tooltip as ReactTooltip } from "react-tooltip";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import CustomTable from "../../components/customTable";
import * as CampaignData from "../../common/data/campaign";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";
import Select from "react-dropdown-select";
import Style from "../../resources/style";
import { toast } from "react-toastify";
import CreateCollectionGrid from "../collection/collectionGrid";
import SideMenu from "../sideMenu";
import Header from "../header";
import fi from "date-fns/esm/locale/fi/index.js";
import InfluencerDetailModal from "../../modal/influencerDetialModal";
import SubscriptionModal from "../../modal/subscriptionModal";
import { isArray } from "jquery";
const options = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "all", label: "All" },
];
const verifyOrNot = [
  { value: 1, label: "Verified" },
  { value: 0, label: "All" },
];
const inviteOption = [
  { value: 1, label: "Yes" },
  { value: 0, label: "No" },
];

const followers = [
  { value: "1000", label: "Nano    1K - 10k " },
  { value: "10000", label: "Micro  10k - 50k " },
  { value: "50000", label: "Mid Tier  50k - 2.5L" },
  { value: "250000", label: "Macro  2.5L - 10L" },
  { value: "1000000", label: "Top Tier  1M+ " },
];
const customBidStyles = {
  headRow: {
    style: {
      backgroundColor: "var(--general-secondary-color)", // Change header background color
      color: "var(--gray-gray-300)", // Change header text color
      fontSize: "14px",
      fontWeight: 600,
      lineHight: "19.2px",
      border: "none",
    },
  },
  headCells: {
    style: {
      padding: "10px 0px 10px 20px",
    },
  },
  rows: {
    style: {
      cursor: "pointer",
      fontSize: "14px",
      paddingTop: "10px",
      paddingBottom: "10px",
      fontWeight: 400,
      lineHight: "19.2px",
      "&:hover": {
        backgroundColor: "var(--general-secondary-color)",
        fontWeight: 600,
      },
    },
  },
  cells: {
    style: {
      paddingLeft: "20px",
    },
  },
};

const sortTypes = [
  {
    sortBy: "followers",
    sortOrder: "asc",
    label: "Followers - Low to High",
  },
  {
    sortBy: "followers",
    sortOrder: "desc",
    label: "Followers - High to Low",
  },
];
const platformTypes = [
  {
    platformName: "btll",
    label: "Instagram",
  },
  {
    platformName: "nxjo",
    label: "Youtube",
  },
];

const limit = 10;
function InfluencerListTable() {
  const [isLoading, setLoading] = useState(false);
  const [modalIsOpenCollection, setModalIsOpenCollection] = useState(false);
  const [modalIsOpenCollectionGrid, setModalIsOpenCollectionGrid] =
    useState(false);
  const [isInviteLoading, setInviteLoading] = useState({});
  const [start, setStart] = useState(0);
  const [next, setNext] = useState(10);
  const [minFollower, setMinFollower] = useState([]);

  const [openSnackbar] = useSnackbar();
  const [hoveredIcons, setHoveredIcons] = useState([]);

  const [sortBy, setSortBy] = useState(null);
  const [sortOrder, setSortOrder] = useState(null);
  const [filterLabel, setFilterLabel] = useState("");
  const [platformLabel, setPlatformLabel] = useState("Instagram");
  const [filterDropDown, setFilterDropDown] = useState(false);
  const [platformDropDown, setPlatformDropDown] = useState(false);
  const [platformId, setPlatformId] = useState("btll");
  const [influencerList, setInfluencerList] = useState([]);
  const [influencerInviteList, setInfluencerInviteList] = useState([]);
  const [filterOpen, setFilterOpen] = useState(false);
  const [listLoading, setListLoading] = useState(false);
  const [searchBio, setSearchBio] = useState(null);

  const [influencerFlyOut, setInfluencerFlyOut] = useState(false);
  const [influencerId, setInfluencerId] = useState(null);

  const [city, setCity] = useState([]);
  const [state, setState] = useState([]);
  const [influencerLanguage, setInfluencerLanguage] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [subscriptionSelected, setSubscriptionSelected] = useState(null);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [subscriptionList, setSubscriptionList] = useState(null);
  const [profession, setProfession] = useState([]);
  const [invitedMark, setInvitedMark] = useState([]);
  const [influencerGender, setInfluencerGender] = useState([]);

  const [verifiedOrNot, setVerifiedOrNot] = useState([]);

  const [clearFilter, setClearFilter] = useState(false);
  const [category, setCategory] = useState([]);
  const [hoveredId, setHoveredId] = useState(null);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [showCollectionBtn, setShowCollectionBtn] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [filterData, setFilterData] = useState({
    gender: null,
    city: [],
    state: [],
    follower: [],
    category: [],
    profession: [],
    language: [],
    counter: null,
    invite_mark: null,
    verified: null,
  });

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // If screen width is less than or equal to 768px, consider it mobile
  };

  useEffect(() => {
    // Add an event listener to handle resize
    window.addEventListener("resize", handleResize);

    // Set initial value
    handleResize();

    // Cleanup listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (platformId) {
      getInfluencerDiscoveryList(1);
    }
  }, [platformId]);

  var redux_dispatch = useDispatch();
  const navigate = useNavigate();

  const redux_selector = useSelector((state) => {
    return {
      BrandUser: state.BrandUserDataReducer,
      Auth: state.BrandUserAuthReducer,
    };
  });
  const { BrandUser = {} } = redux_selector;
  const { brand_deep_data = {} } = BrandUser;
  const { org_deep_data = {} } = BrandUser;

  function updateProgress(status) {
    setLoading(status);
  }

  let is_subscribe = false;

  function getInfluencerDiscoveryList(page) {
    inviteListFilterFun();

    let newPage = 0;
    if (page == 10) {
      newPage = 0 * Number(page);
    } else {
      newPage = 50 * Number(page) - 50;
    }

    setListLoading(true);
    let filter_data = {
      minimum_followers: Number(filterData.follower),
      location: filterData.state,
      gender: filterData.gender,
      categories: filterData?.category,
      profession: filterData.profession,
      language: filterData?.language,
      is_invited: filterData?.invite_mark?.toString(),
      search_text: searchBio,
      is_verified: filterData.verified,
    };
    var params = {
      platform_id: platformId ? platformId : "btll",
      org_id: brand_deep_data?.brand_data?.org_id,
      // limit: is_subscribe ? null : 10,
      filter_data: JSON.stringify(filter_data),
    };

    if (sortBy != null) {
      params.sort_by = sortBy;
    }
    if (sortOrder != null) {
      params.sort_order = sortOrder;
    }
    Core.getInfluencerDiscovery(
      getInfluencerDiscoveryListSuccess,
      getInfluencerDiscoveryListFailed,
      updateProgress,
      params
    );
  }

  function getInfluencerDiscoveryListSuccess(response_data) {
    localStorage.setItem(
      "INFLUENCER_LIST_STORAGE",
      JSON.stringify(response_data)
    );

    if (response_data && response_data?.influencer_list?.data) {
      setInfluencerList([]);
    } else {
      setInfluencerList(response_data?.influencer_list);
    }

    setLoading(false);
  }

  function getInfluencerDiscoveryListFailed(errorList) {
    toast.error(errorList);
    setInfluencerList([]);
    setLoading(false);
  }

  const handlePageChange = (page) => {
    getInfluencerDiscoveryList(page);
  };

  // filter sub category

  const getSelectedPlatformData = (platforms_data) => {
    //   console.log("data?.", Utils.isObjectOrNullUndefinedZero(platforms_data));
    if (!Utils.isEmpty(platforms_data)) {
      return platforms_data?.find((item) => item?.platform_id === platformId);
    }
  };

  const formatPlatformData = (platforms_data, key) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    return Utils.changeNumberFormate(selectedPlatformData?.[key]);
  };

  const getEngagementRate = (platforms_data) => {
    const selectedPlatformData = getSelectedPlatformData(platforms_data);
    var eng_data = null;

    var eng_rate = null;
    if (selectedPlatformData?.avg_likes && selectedPlatformData?.avg_comments) {
      eng_data =
        Number(selectedPlatformData?.avg_likes) +
        Number(selectedPlatformData.avg_comments);

      eng_rate = (eng_data / Number(selectedPlatformData.avg_views)) * 100;
    }

    return eng_rate?.toFixed(1);
  };

  function inviteListFilterFun() {
    setFilterOpen(false);
  }

  function inviteListFilterFunOpen() {
    setFilterOpen(true);
  }

  const toggleFlyoutDropdown = () => {
    setInfluencerFlyOut(!influencerFlyOut);
  };

  function updateLanguage(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        language: value,
      };
    });

    setInfluencerLanguage(selectedList);
  }

  function updateCategory(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.value);
    });
    setFilterData((prev) => {
      return {
        ...prev,
        category: value,
      };
    });
    setCategory(selectedList);

    value.flatMap((key) => CampaignData.FULL_CATEGORIES.subcategories[key]);

    setSubCategoryData(
      value.flatMap((item) => CampaignData.FULL_CATEGORIES.subcategories[item])
    );
  }

  function updateState(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        state: value,
      };
    });

    setState(selectedList);
  }

  function updateInviteMark(selectedItem) {
    setInvitedMark(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          invite_mark: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function clearFilterFun() {
    setCity([]);
    setState([]);
    setInfluencerGender([]);
    setProfession([]);
    setInvitedMark([]);
    setInfluencerLanguage([]);
    setMinFollower([]);
    setSearchBio(null);
    setVerifiedOrNot([]);

    setFilterData({
      gender: null,
      city: [],
      state: [],
      follower: [],
      category: [],
      invite_mark: null,
      language: [],
      verified: null,
    });
    setClearFilter(true);
  }

  function updateGender(selectedItem) {
    setInfluencerGender(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          gender: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }
  function updateVerifiedOrNot(selectedItem) {
    setVerifiedOrNot(selectedItem);
    if (selectedItem.length !== 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          verified: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function updateMinFollower(selectedItem) {
    setMinFollower(selectedItem);

    if (selectedItem.length != 0) {
      setFilterData((prev) => {
        return {
          ...prev,
          follower: selectedItem ? selectedItem[0].value : [],
        };
      });
    }
  }

  function updateProfession(selectedList, selectedItem) {
    var value = [];
    selectedList.forEach((item) => {
      value.push(item.id);
    });

    setFilterData((prev) => {
      return {
        ...prev,
        profession: value,
      };
    });

    setProfession(selectedList);
  }

  function flyoutBidListFilter() {
    return (
      <div
        onClick={() => inviteListFilterFun()}
        className={`sidebar ${filterOpen ? "open" : ""}`}
      >
        <div
          className="sidebar-content-filter"
          onClick={(e) => e.stopPropagation()}
        >
          <div
            style={{ textAlign: "right", marginRight: 20 }}
            className="cursor-pointer"
            onClick={() => inviteListFilterFun()}
          >
            <Icon iconName="CrossBtnIcon" />
          </div>

          <div className="sidebar-content-filter-inner">
            <div
              style={{
                overflowX: "hidden",
                overflowY: "auto",
                height: "90%",
                paddingRight: 20,
                paddingBottom: 110,
              }}
            >
              <div style={{ textAlign: "left" }}>
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Influencer's Level"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={followers}
                  values={minFollower}
                  onChange={(value) => updateMinFollower(value)}
                  placeholder="Select level"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              <div style={{ textAlign: "left" }} className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Verification Status"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={verifyOrNot}
                  values={verifiedOrNot}
                  onChange={(value) => updateVerifiedOrNot(value)}
                  placeholder="Select option"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              <div className="m-t-20" style={{ textAlign: "left" }}>
                <div style={{ gap: 5 }} className="m-b-5 flex-row">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Search bio text"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <div>
                  <input
                    className="discovery-search-influencer"
                    type="text"
                    value={searchBio}
                    placeholder="Search bio text"
                    onChange={(e) => {
                      setSearchBio(e.target.value);
                    }}
                  />
                </div>
              </div>
              <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Location"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select Location"
                  id="css_custom"
                  options={CampaignData.CITY}
                  selectedValues={state}
                  onSelect={(selectedList, selectedItem) =>
                    updateState(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateState(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"label"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
              <div style={{ textAlign: "left" }} className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Gender"}
                    textAlign={"text_align_left"}
                  />
                </div>

                <Select
                  multi={false}
                  options={options}
                  values={influencerGender}
                  onChange={(value) => updateGender(value)}
                  placeholder="Select gender"
                  autoFocus={false}
                  color="#c8c2c2"
                  // Customcolor
                  style={{
                    border: "1px solid rgb(230, 231, 232)",
                    backgroundColor: "#fff",
                    borderRadius: 4,
                    paddingBottom: "12px",
                    paddingTop: "12px",
                    option: {
                      backgroundColor: "red",
                    },
                  }}
                />
              </div>
              <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Category"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select category"
                  id="css_custom"
                  selectedValues={category}
                  options={Object.values(
                    CampaignData.FULL_CATEGORIES.CATEGORYS
                  )}
                  onSelect={(selectedList, selectedItem) =>
                    updateCategory(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateCategory(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"label"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
              <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Profession"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Select profession"
                  id="css_custom"
                  selectedValues={profession}
                  options={subCategoryData}
                  onSelect={(selectedList, selectedItem) =>
                    updateProfession(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateProfession(selectedList, selectedItem)
                  }
                  singleSelect={false}
                  displayValue={"name"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
              <div className="m-t-20">
                <div className="m-b-5">
                  <CustomText
                    p
                    fontSize={"font_size_medium"}
                    textColor={"text_color_primary"}
                    text={"Language"}
                    textAlign={"text_align_left"}
                  />
                </div>
                <Multiselect
                  placeholder="Choose language"
                  id="css_custom"
                  options={Object.values(CampaignData.LANGUAGE)}
                  onSelect={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  onRemove={(selectedList, selectedItem) =>
                    updateLanguage(selectedList, selectedItem)
                  }
                  selectionLimit={3}
                  selectedValues={influencerLanguage}
                  singleSelect={false}
                  displayValue={"n"}
                  showArrow={false}
                  style={{
                    chips: Style.chips,
                    option: Style.option,
                    multiselectContainer: Style.multiselectContainerFilter,
                    searchBox: Style.searchBox,
                  }}
                />
              </div>
            </div>
            <div className="filter-status-bottom-container">
              <div>
                <PrimaryCta
                  fifth
                  onClick={() => getInfluencerDiscoveryList(1)}
                  fontSize={"font_size_large"}
                  text={"Apply Filter"}
                  isLoading={""}
                  className="apply-btn-filter"
                />
              </div>
              <div>
                <PrimaryCta
                  invert
                  onClick={() => clearFilterFun()}
                  fontSize={"font_size_large"}
                  text={"Clear Filter"}
                  isLoading={""}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  const openModal = () => {
    if (modalIsOpen == false) {
      setModalIsOpen(true);
    } else {
      setModalIsOpen(false);
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  function openModalCollection() {
    if (modalIsOpenCollection == false) {
      setModalIsOpenCollection(true);
    } else {
      setModalIsOpenCollection(false);
    }
  }

  function closeModalCollection() {
    setModalIsOpenCollection(false);
  }
  function openModalCollectionGrid() {
    if (modalIsOpenCollectionGrid == false) {
      setModalIsOpenCollectionGrid(true);
    } else {
      setModalIsOpenCollectionGrid(false);
    }
  }

  function closeModalCollectionGrid() {
    setModalIsOpenCollectionGrid(false);
  }

  function hoveredButton() {
    return (
      <button
        onClick={openModalCollectionGrid}
        className={`button-hover-show-btn ${
          showCollectionBtn ? "hidden-hover-show-btn" : ""
        }`}
      >
        Add Collection
      </button>
    );
  }

  console.log(
    "influencerList is_protected",
    influencerList[3]?.influencer_data?.is_protected
  );
  const conditionalRowStyles = [
    {
      when: (row) => row.influencer_data.is_protected == true,
      style: {
        filter: "blur(5px)",
        color: "black",
        "&:hover": {
          cursor: "pointer",
        },
      },
    },
  ];

  const influencerTableSchema = [
    {
      name: "Influencers",
      selector: (row) => row["influencer_data"]["name"],
      width: "",
      cell: (data) => (
        <span
          onClick={(e) => {
            toggleFlyoutDropdown();
            setInfluencerId(
              !Utils.isEmpty(data.platforms_data)
                ? data?.influencer_data.influencer_id
                : null
            );
          }}
          style={{ display: "flex", gap: 8, alignItems: "center" }}
        >
          <Image
            source={
              data.influencer_data.profile_picture
                ? Utils.generateInfluencerDpUrl(
                    data.influencer_data.influencer_id,
                    data.influencer_data.profile_picture
                  )
                : require("../../resources/images/user-copy.png")
            }
            className={
              data.influencer_data.is_verified
                ? "discovery-user-profile"
                : "proposal-user-profile"
            }
          />
          <div style={{ flexDirection: "row", display: "flex", gap: 5 }}>
            <div>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  gap: 5,
                  alignItems: "baseline",
                }}
              >
                <CustomText
                  p
                  text={data.influencer_data.name}
                  className="Body-1"
                />
                {/* <div>
                  <CustomText
                    p
                    text={`(${data.influencer_data.ratings.toFixed(1)})`}
                    className="typographyH5"
                  />
                </div> */}
              </div>
              <CustomText
                p
                text={getInflencerLocation(data?.influencer_data)}
                fontSize={"font_size_extraSmall"}
                fontWeight={"rubik_regular"}
                textColor={"text_color_primary"}
                textAlign={"text_align_left"}
              />
            </div>
          </div>
        </span>
      ),
    },
    {
      name: "Platform",
      selector: (row) =>
        !Utils.isEmpty(row?.platforms_data)
          ? platFormName(
              row?.platforms_data,
              row?.influencer_data?.influencer_id
            )
          : "N/A",

      // sortable: true,
      // width: "100px",
      omit: isMobile,
    },
    {
      name: "Followers",
      selector: (row) =>
        !Utils.isEmpty(row?.platforms_data)
          ? formatPlatformData(row?.platforms_data, "followers")
          : "N/A",
      textAlign: "center",
      omit: isMobile,
    },
    // {
    //   name: "Like",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_likes"),
    //   width: "90px",
    // },
    // {
    //   name: "Comment",
    //   selector: (row) => formatPlatformData(row.platforms_data, "avg_comments"),
    //   width: "90px",
    // },
    {
      name: "Avg Views",
      // width: "100px",
      omit: isMobile,
      selector: (row) =>
        !Utils.isEmpty(row?.platforms_data)
          ? Utils.isObjectOrNullUndefinedZero(
              formatPlatformData(row?.platforms_data, "avg_views")
            )
            ? "-"
            : formatPlatformData(row?.platforms_data, "avg_views")
          : "N/A",
    },
    {
      name: "ER",
      omit: isMobile,
      selector: (row) =>
        Utils.isObjectOrNullUndefinedZero(
          formatPlatformData(row?.platforms_data, "engagement_rate")
        )
          ? "-"
          : getEngagementRate(row?.platforms_data),

      cell: (data) => (
        <span>
          <Tooltip
            title={
              <>
                <div>
                  Avg Likes ={" "}
                  {Utils.isObjectOrNullUndefinedZero(data?.platforms_data) &&
                    formatPlatformData(data?.platforms_data, "avg_likes")}
                </div>
                <div>
                  Avg Comments ={" "}
                  {Utils.isObjectOrNullUndefinedZero(data?.platforms_data) &&
                    formatPlatformData(data?.platforms_data, "avg_comments")}
                </div>
              </>
            }
          >
            {!Utils.isEmpty(data?.platforms_data) ? (
              <>
                {" "}
                {Utils.isObjectOrNullUndefinedZero(
                  Number(
                    formatPlatformData(data?.platforms_data, "engagement_rate")
                  )
                )
                  ? "-"
                  : Number(
                      formatPlatformData(
                        data?.platforms_data,
                        "engagement_rate"
                      )
                    ).toFixed(2) + " %"}
              </>
            ) : (
              "N/A"
            )}
          </Tooltip>
        </span>
      ),
    },

    // {
    //   name: "",
    //   lastCol: true,

    //   conditionalCellStyles: [
    //     {
    //       when: (row) => true,
    //       style: {
    //         justifyContent: "right",
    //       },
    //     },
    //   ],
    //   cell: (data) => <span></span>,

    //   // sortable: true,
    // },
    {
      name: "",
      paddingRight: "20px",
      cell: (data) => (
        <span style={{}}>
          <CustomButton
            onClick={() => {
              setHoveredId(data?.influencer_data?.influencer_id);
              openModalCollectionGrid();
            }}
            text={"Add to Collection"}
            fontSize={"font_size_small"}
            isLoading={isInviteLoading[data?.influencer_data?.influencer_id]}
            borderRadius={4}
            padding={{ top: 12, right: 12, bottom: 12, left: 12 }}
            textColor={"text_color_light"}
            background={"#090A14"}
            border={"none"}
            boxShadow={"none"}
            hoverColor={"var(--hover-color, #1977F2)"}
          />
          {data.influencer_data.influencer_id == hoveredId}
        </span>
      ),
    },
  ];

  function inviteAndReject(id) {
    let inviteId = influencerInviteList.find(
      (item) => item.influencer_id == id
    );
    return inviteId;
  }

  const platFormName = (platforms_data, influencer_id) => {
    const handleMouseEnter = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = true;
      setHoveredIcons(newHoveredIcons);
    };

    const handleMouseLeave = (index) => {
      const newHoveredIcons = [...hoveredIcons];
      newHoveredIcons[index] = false;
      setHoveredIcons(newHoveredIcons);
    };

    let platformData;
    if (!Utils.isEmpty(platforms_data)) {
      platformData = platforms_data.find(
        (item) => item?.platform_id === platformId
      );
    }

    const handlePlatformClick = (url) => {
      // Open the platform URL here
      window.open(url, "_blank");
      // Reset all hover states to false after clicking
      setHoveredIcons(new Array(platforms_data.length).fill(false));
    };

    return (
      <>
        {platforms_data.length > 0 &&
          platformData &&
          platforms_data[0].platform_id != null && (
            <div>
              {/* <CustomText
              p
              text={"Social Presence"}
              fontSize={"font_size_small"}
              textColor={"text_color_black"}
              fontWeight={"Poppins_semiBold"}
            /> */}
            </div>
          )}
        <div style={{ marginTop: 7 }} className="flex-row">
          <div
            className={"platform-name"}
            onMouseEnter={() =>
              handleMouseEnter(`${influencer_id}${platformData?.platform_id}`)
            }
            onMouseLeave={() =>
              handleMouseLeave(`${influencer_id}${platformData.platform_id}`)
            }
            onClick={() =>
              handlePlatformClick(
                Utils.getSocialUrl(
                  platformData?.platform_id,
                  platformData?.identifier_id
                )
              )
            }
          >
            {platformData?.platform_id && (
              <Icon
                iconName={
                  hoveredIcons[`${influencer_id}${platformData?.platform_id}`]
                    ? Utils.getSocialIconHover(platformData?.platform_id)
                    : Utils.getSocialIcon(platformData?.platform_id)
                }
                height={14}
                width={14}
              />
            )}
          </div>
        </div>
      </>
    );
  };

  function influencerDetail(influencer_id) {
    const url = `/influencer/${influencer_id}`;
    window.open(url, "_blank");
  }

  function getInflencerLocation(influencer_data) {
    if (influencer_data["city_id"]) {
      return CampaignData.CITY.find(
        (city) => city.id == influencer_data["city_id"]
      )?.label;
    } else if (influencer_data["state"]) {
      return CampaignData.LOCATIONS[influencer_data["state"]]?.n;
    } else {
      return "-";
    }
  }

  function filterDropDownOpenClose() {
    if ((sortBy != null) & (sortOrder != null)) {
      setSortBy(null);
      setSortOrder(null);
      setFilterLabel(null);
    } else {
      SortDropDown();
    }
  }

  function SortDropDown() {
    if (filterDropDown) {
      setFilterDropDown(false);
    } else {
      setFilterDropDown(true);
    }
  }
  function filterPlatformClose() {
    if (platformId != null) {
      setPlatformId(null);
      setPlatformLabel(null);
    } else {
      platformDropDownFun();
    }
  }

  function platformDropDownFun() {
    if (platformDropDown) {
      setPlatformDropDown(false);
    } else {
      setPlatformDropDown(true);
    }
  }
  function clearFilterCategory() {
    let cateData = filterData;
    cateData["category"] = [];
    setFilterData(cateData);

    setCategory([]);
    getInfluencerDiscoveryList();
  }

  function filterDataShowCategory() {
    if (filterData.category && filterData.category.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.category.length} Categories`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterCategory()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterGender() {
    let genderData = filterData;
    genderData["gender"] = null;
    setFilterData(genderData);

    setInfluencerGender([]);
    getInfluencerDiscoveryList();
  }
  function filterDataShowGender() {
    if (filterData.gender) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`Gender ${filterData.gender}`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterGender()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }

  function clearFilterVerifiedOrNot() {
    let verified = filterData;
    verified["verified"] = null;
    setFilterData(verified);

    setVerifiedOrNot([]);
    getInfluencerDiscoveryList();
  }
  function filterDataVerified() {
    if (filterData.verified) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={"Verified"}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterVerifiedOrNot()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterLevel() {
    let followerData = filterData;
    followerData["follower"] = [];
    setFilterData(followerData);

    setMinFollower([]);
    getInfluencerDiscoveryList();
  }

  function filterDataShowLevel() {
    if (filterData.follower && filterData.follower?.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${Utils.getFollowersValue(filterData.follower)}`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterLevel()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterLocation() {
    let stateData = filterData;
    stateData["state"] = [];
    setFilterData(stateData);

    setState([]);
    getInfluencerDiscoveryList();
  }
  function filterDataShowLocation() {
    if (filterData.state && filterData.state.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.state.length} Locations`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterLocation()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterLanguage() {
    let languageData = filterData;
    languageData["language"] = [];
    setFilterData(languageData);

    setInfluencerLanguage([]);
    getInfluencerDiscoveryList();
  }
  function filterDataShowLanguage() {
    if (filterData.language && filterData.language.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.language.length} Languages`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterLanguage()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }
  function clearFilterProfession() {
    let professionData = filterData;
    professionData["profession"] = [];
    setFilterData(professionData);

    setProfession([]);
    getInfluencerDiscoveryList();
  }

  function filterDataShowProfession() {
    if (filterData.profession && filterData.profession.length > 0) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={`${filterData.profession.length} Professions`}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterProfession()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }

  function clearFilterSearchName() {
    setSearchBio(null);
  }
  function filterDataShowSearchName() {
    if (searchBio) {
      return (
        <div className="label-of-filter-card-container">
          <CustomText
            p
            text={searchBio}
            fontSize={"font_size_regular"}
            textColor={"text_color_black"}
            textAlign={"text_align_right"}
          />
          <div
            onClick={() => clearFilterSearchName()}
            className="close-filters-data"
          >
            <Icon iconName="CrossBtnIcon" />
          </div>
        </div>
      );
    }
  }

  // const influencerObject = influencerList.map((item, index) => ({
  //   index: index + 1,
  //   name: item.influencer_data.name,
  //   platform: item.platforms_data,
  //   influencer_id: item.influencer_data.influencer_id,
  //   followers: formatPlatformData(item.platforms_data, "followers"),
  //   avgViews: Utils.isObjectOrNullUndefinedZero(
  //     formatPlatformData(item.platforms_data, "avg_views")
  //   )
  //     ? "-"
  //     : formatPlatformData(item.platforms_data, "avg_views"),
  //   engagementRate: Utils.isObjectOrNullUndefinedZero(
  //     formatPlatformData(item.platforms_data, "engagement_rate")
  //   )
  //     ? "-"
  //     : `${Number(
  //         formatPlatformData(item.platforms_data, "engagement_rate")
  //       ).toFixed(2)} %`,
  //   profile_picture: item.influencer_data.profile_picture,
  //   isVerified: item.influencer_data.is_verified,
  //   location: getInflencerLocation(item.influencer_data),
  //   avgLikes: formatPlatformData(item.platforms_data, "avg_likes"),
  //   avgComments: formatPlatformData(item.platforms_data, "avg_comments"),
  // }));
  function ActionOnLoadHandler(subscription_list) {
    setSubscriptionList(subscription_list);
  }
  console.log("setSubscriptionList", subscriptionList);

  return (
    <div className="dashboard-container">
      <SideMenu />
      <div className="main-screen">
        <Header
          onLoadHeader={(subscription_list) =>
            ActionOnLoadHandler(subscription_list)
          }
        />
        <div style={{ minHeight: 500 }}>
          <div className="page-container-campaignList">
            <div className="bid-list-dropdown-header-container m-l-20 m-r-20">
              <div>
                {influencerList && (
                  <div
                    style={{ marginTop: 12 }}
                    className="flex-row justify-between align-center"
                  >
                    <CustomText
                      p
                      text={"Top "}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular_medium"}
                    />
                    &nbsp;
                    <CustomText
                      p
                      text={`${start + 1}-${
                        influencerList && influencerList?.length
                      } 
                   Results`}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_primary"}
                      fontWeight={"Poppins_regular"}
                    />
                  </div>
                )}
              </div>
              <div style={{ gap: 5 }} className="flex-row m-l-5">
                {/* <div className="dropdown-button-container">
                  <button
                    onClick={() => filterDropDownOpenClose()}
                    className="dropdown-button"
                  >
                    <CustomText
                      p
                      text={filterLabel ? filterLabel : "Sort by"}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_black"}
                      textAlign={"text_align_right"}
                    />
                    {filterLabel ? (
                      <div className="cursor-pointer">
                        <Image
                          source={require("../../resources/images/bid-search-cross.png")}
                        />
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <Icon
                          iconName={"DropdownIcon"}
                          height={18}
                          width={18}
                        />
                      </div>
                    )}
                  </button>
                  {filterDropDown && (
                    <div className="dropdown-list-container-discovery">
                      {sortTypes.map((item) => {
                        return (
                          <div
                            onClick={() => {
                              setNext(0);
                              setSortBy(item.sortBy);
                              setSortOrder(item.sortOrder);
                              SortDropDown();
                              setFilterLabel(item.label);
                            }}
                            className={
                              item.sortBy == sortBy &&
                              item.sortOrder == sortOrder
                                ? "dropdown-button-list-selected"
                                : "dropdown-button-list"
                            }
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div> */}

                <div className="dropdown-button-container-discovery">
                  <button
                    onClick={() => platformDropDownFun()}
                    className="dropdown-button"
                  >
                    <CustomText
                      p
                      text={platformLabel ? platformLabel : "Platform"}
                      fontSize={"font_size_regular"}
                      textColor={"text_color_black"}
                      textAlign={"text_align_right"}
                    />
                    {platformLabel ? (
                      <div className="cursor-pointer">
                        <div className="cursor-pointer">
                          <Icon
                            iconName={"DropdownIcon"}
                            height={18}
                            width={18}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="cursor-pointer">
                        <Icon
                          iconName={"DropdownIcon"}
                          height={18}
                          width={18}
                        />
                      </div>
                    )}
                  </button>
                  {platformDropDown && (
                    <div className="dropdown-list-container-invite">
                      {platformTypes.map((item) => {
                        return (
                          <div
                            onClick={() => {
                              setNext(0);
                              setPlatformId(item.platformName);
                              platformDropDownFun();
                              setPlatformLabel(item.label);
                            }}
                            className={
                              item.platformName == platformId
                                ? "dropdown-button-list-selected"
                                : "dropdown-button-list"
                            }
                          >
                            {item.label}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
                {filterDataShowCategory()}
                {filterDataShowLanguage()}
                {filterDataShowGender()}
                {filterDataVerified()}
                {filterDataShowLevel()}
                {filterDataShowLocation()}
                {filterDataShowProfession()}
                {filterDataShowSearchName()}
                <div
                  className="filter-icon-btn-invite"
                  onClick={() => inviteListFilterFunOpen()}
                >
                  <Icon iconName="filterIconNew" />
                </div>
              </div>
            </div>
            {isLoading ? (
              <Loader />
            ) : (
              <>
                {influencerList[3]?.influencer_data?.is_protected && (
                  <SubscriptionBuyButton onClick={openModal} />
                )}

                {influencerList.length !== 0 && influencerList ? (
                  <div className="custom-table">
                    <CustomTable
                      className="CustomTable"
                      tableSchema={influencerTableSchema}
                      paginationPerPage={10}
                      tableData={influencerList}
                      rowPaddingTop={16}
                      rowPaddingBottom={16}
                      customStyles={customBidStyles}
                      conditionalRowStyles={conditionalRowStyles}
                      progressPending={isLoading}
                      paginationTotalRows={influencerList?.length}
                      // onChangePage={handlePageChange}
                      // onChangeRowsPerPage={getInfluencerListChange}

                      // onRowClicked={(item) =>
                      //   CampaignClick(item.campaign_data.campaign_id)
                      // }
                      progressComponent={
                        <div className="fall-back-center-campaign">
                          <Loader />
                        </div>
                      }
                      pagination
                    />
                  </div>
                ) : (
                  <div className="fall-back-center-campaign">
                    <FallBack
                      heading={"No Influencers Found"}
                      title={
                        "No influencers found. Adjust your filters for better results."
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
          {filterOpen && flyoutBidListFilter()}
          {modalIsOpenCollectionGrid && (
            <CreateCollectionGrid
              closeModal={closeModalCollectionGrid}
              id={hoveredId}
            />
          )}

          {modalIsOpen && (
            <SubscriptionModal
              subscriptionData={subscriptionList}
              closeModal={closeModal}
              orgData={org_deep_data?.org_data}
            />
          )}

          {influencerFlyOut && influencerId && (
            <InfluencerDetailModal
              influencerFlyOut={influencerFlyOut}
              toggleFlyoutDropdown={toggleFlyoutDropdown}
              influencer_id={influencerId}
              org_id={brand_deep_data.brand_data.org_id}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default InfluencerListTable;
