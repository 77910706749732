import { AssignmentIcon } from "../components/assets/icons";
import Config from "./config";

import HttpBrowserRequest from "./http_request";

/////////////////////////// Public Functions START /////////////////////////////
const Core = {
  // Api for signup as Brand
  brandSignup: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);

    // http Request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function
        // Incase of error
        progress_cb(false); // Screen progress set to false
        if (!response.success) {
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_BRAND_SIGNUP),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  // Api for Login as Brand
  brandLogin: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);

    // http Request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_BRAND_LOGIN),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  // Api for signup as User
  userSignup: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_CREATE_USER),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  // Api for getting logged user data
  userSelfData: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);

    // http Request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function

        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_USER_SELF_DATA),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  // Api for Create Campaign
  createCampaignUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_CREATE_CAMPAIGN_USER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  // Api for Get Brand Report
  getBrandReports: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_BRAND_REPORT),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Create Campaign
  getCampaignListUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_LIST_USER
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Create Campaign
  getCampaignUserData: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_USER
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Get Brand Data
  getBrandData: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_BRAND),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Create Brand
  createBrand: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_CREATE_BRAND),
      Config.POST_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Generate brand image url as User
  generateUploadBrandImageUrl: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
    file
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response, file);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GENEREATE_UPLOAD_URL
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  // Api for Update brand logo as User
  updateBrandLogo: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_BRAND_LOGO
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  // Api for Create Brand
  userLogout: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },

      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_USER_LOGOUT),
      Config.POST_METHOD,
      params,
      "application/json",

      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_USER_LOGOUT_SELF),
      Config.POST_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for  Brand list
  brandList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);

    // http Request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_BRAND_LIST),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  // Api for Get Campaign Bids user
  getCampaignBidsUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_BIDS_USER
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Get order list user
  getOrderListUser: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_ORDER_LIST_USER
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // // Api for post  Approve list user
  // approveBidUser: function (succes_cb, failed_cb, progress_cb, params, auth) {
  //   // Screen progress set to true
  //   progress_cb(true);

  //   // http request
  //   HttpBrowserRequest.fetch(
  //     function (error, response) {
  //       // Callback function

  //       // Incase of error
  //       if (!response.success) {
  //         progress_cb(false); // Screen progress set to false
  //         return failed_cb(response.err_l);
  //       }
  //       progress_cb(false); // Screen progress set to false

  //       // Return sucess response
  //       return succes_cb(response);
  //     },
  //     _api_function.constructUrn(
  //       Config.API_ENDPOINTS.API_URN_POST_APPROVE_BID_USER
  //     ),
  //     Config.POST_METHOD,
  //     params,
  //     "application/json",
  //     auth
  //   );
  // },

  // Api for post  Approve list user
  postSortlistBidUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_SHORTLIST_BID_USER
      ),
      Config.POST_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for post  Approve list user
  postRejectBidUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_REJECT_BID_USER
      ),
      Config.POST_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Get Campaign Bids user
  getCategory: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_CATEGORY),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  // Api for Get Campaign Bids user
  getCampaignContent: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_CONTENT
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  getCampaignUserDetail: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CAMPAIGN_USER_DETAIL
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  getBidsInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_BID_INFLUENCER_LIST
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  postAssignmentWithdraw: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ASSIGNMENT_USER_WITHDRAW
      ),
      Config.POST_METHOD,
      params,
      "application/json",
      auth
    );
  },

  getAssignmentUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_ASSIGNMENT_USER),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  getAssignmentUser: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_ASSIGNMENT_USER),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  getBidList: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_BID_LIST),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },
  approveBidUser: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_APPROVE_BID_USER),
      Config.POST_METHOD,
      params,
      "application/json",
      auth
    );
  },

  generateUploadSampleMediaUrl: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_UPLOAD_MEDIA_URL),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getTransactionList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_TRANSACTION_USER),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },
  getOrgDataUser: function (succes_cb, failed_cb, progress_cb, params, auth) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_ORG_DATA_USER),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },

  getUserAssignmentDetail: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ASSIGNMENT_USER_DETAIL
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },
  postUserAssignmentReviewContent: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ASSIGNMENT_REVIEW_CONTENT
      ),
      Config.POST_METHOD,
      params,
      "application/json",
      auth
    );
  },
  getInfluencerDetailPublic: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    auth
  ) {
    // Screen progress set to true
    progress_cb(true);

    // http request
    HttpBrowserRequest.fetch(
      function (error, response) {
        // Callback function

        // Incase of error
        if (!response.success) {
          progress_cb(false); // Screen progress set to false
          return failed_cb(response.err_l);
        }
        progress_cb(false); // Screen progress set to false

        // Return sucess response
        return succes_cb(response);
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_DETAIL_PUBLIC
      ),
      Config.GET_METHOD,
      params,
      "application/json",
      auth
    );
  },
  getMembersList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_MEMBER_LIST),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  postInviteMembers: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_INVITE_MEMBER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  postRemoveMembers: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_REMOVE_MEMBER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getTransactionHistoryList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_TRANSACTION_HISTORY
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  getMessagesUserList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_MESSAGE_LIST),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  postMessagesUserList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_SEND_MESSAGE
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  postDeleteMessage: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_DELETE_MESSAGE
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postUpdateCampaign: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_UPDATE_CAMPAIGN
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getCreditList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_CREDIT_LIST),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  getCreditDetail: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CREDIT_DETAIL
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  postCreditOrder: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_CREDIT_ORDER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postForgotPassword: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_FORGOT_PASSWORD
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postVerifyForgotPassword: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_VERIFY_FORGOT_PASSWORD
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  //   succes_cb,
  //   failed_cb,
  //   progress_cb,
  //   params
  // ) {
  //
  //   // Screen progress set to true
  //   progress_cb(true);
  //   // http request
  //   HttpBrowserRequest.fetch(
  //     function (error, response, status) {
  //       // Callback function
  //       progress_cb(false);
  //       if (error) {
  //         return failed_cb("Something went wrong");
  //       }
  //       if (response) {
  //         if (response.success) {
  //           return succes_cb(response);
  //         }
  //         return failed_cb(response.err_l.map((item) => item.m).join(", "));
  //       }
  //       return failed_cb("Something went wrong");
  //     },
  //     _api_function.constructUrn(
  //       Config.API_ENDPOINTS.API_URN_POST_FORGOT_PASSWORD
  //     ),
  //     Config.POST_METHOD,
  //     params,
  //     "application/json"
  //   );
  // },

  getOrgKycDetails: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_ORG_KYC_DETAILS
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  postUpdateUserOrg: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_POST_UPDATE_ORG),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postVerifyEmailOtpOrg: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_VERIFY_EMAIL
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postVerifyEmailOrg: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_REQUEST_EMAIL_VERIFICATION
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postOrgUpdateKyc: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_POST_ORG_ADD_KYC),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getGenerateUploadUrl: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params,
    authToken,
    file
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response, file);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GENERATE_UPLOAD_MEDIA_URL
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  getOrgUserDetails: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_GET_ORG_DETAIL),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  postCreateOrderAssignment: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_CREATE_ORDER_ASSIGNMENT
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postChangePassword: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_CHANGE_PASSWORD),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postUpdateUserProfile: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_UPDATE_USER_DATA),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getAssignmentBrandDetails: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_ASSIGNMENT_BRAND),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  getCampaignReport: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_REPORT_CAMPAIGN
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  updateBrandSetting: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_BRAND_SETTING
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getPayoutList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_PAYOUT_LIST),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  postRatingInfluencer: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_RATTING_INFLUENCER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getCreditsTransactionList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_CREDIT_TRANSACTION
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  postPublishedCampaign: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_PUBLISHED_CAMPAIGN_USER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postUnPublishedCampaign: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UNPUBLISHED_CAMPAIGN_USER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postSaveCampaignUser: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_SAVE_CAMPAIGN_USER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  getInfluencerPlatformInsight: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_PLATFORM_INSIGHT
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },

  getInfluencerInviteList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INVITE_INFLUENCER_LIST
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  getInfluencerList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l.map((item) => item.m).join(", "));
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GET_INFLUENCER_LIST
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  postInfluencerInvite: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_INVITE_INFLUENCER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  postInfluencerInviteWithdraw: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_POST_INFLUENCER_LIST_WITHDRAW
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  postCreateWallet: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_CREATE_WALLET),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  counterProposal: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_BID_USER_CREATE_COUNTER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  adminNotificationSend: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ADMIN_NOTIFICATION
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  userNotificationDetail: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_NOTIFICATION_USER_DETAIL
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  influencerPortfolio: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_PORTFOLIO
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  AssignmentContentList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ASSIGNMENT_CONTENT_LIST
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  AssignmentContentListByContent: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ASSIGNMENT_CONTENT_BY_CONTENT_ID
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  campaignAnalytics: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_CAMPAIGN_ANALYTICS
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  campaignContentAnalytics: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_CAMPAIGN_CONTENT_ANALYTICS
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  postAddCollection: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_ADD_COLLECTION),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  postAddInfluencerCollection: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_ADD_INFLUENCER_COLLECTION
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  getCollectionList: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(Config.API_ENDPOINTS.API_URN_COLLECTION_LIST),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  getCollectionInfluencerList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_COLLECTION_LIST
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  postCollectionDelete: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_COLLECTION_DELETE
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  postCollectionInfluencerDelete: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_COLLECTION_DELETE
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  getInfluencerDiscovery: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_DISCOVERY
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  getEffectiveFollowers: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_PLATFORM_EFFECTIVE_FOLLOWERS
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  generateShareKey: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_COLLECTION_GENERATE_SHARE_KEY
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  addPriceToCollectionInfluencer: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_COLLECTION_ADD_PRICE_INFLUENCER
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getCollectionInfluencerListByShareKey: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_COLLECTION_INFLUENCER_LIST_BY_SHARE_KEY
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  updateCollectionReviewStatus: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_UPDATE_COLLECTION_REVIEW_STATUS
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  getInfluencerContractsListShareData: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_CONTRACTS_LIST_BY_SHARE_KEY
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  generateShareKeyCampaignContractsList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GENERATE_CONTRACTS_SHARE_LIST_KEY
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },

  getInfluencerContractsListShareData: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_INFLUENCER_CONTRACTS_LIST_BY_SHARE_KEY
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  generateShareKeyCampaignContractsList: function (
    succes_cb,
    failed_cb,
    progress_cb,
    params
  ) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_GENERATE_CONTRACTS_SHARE_LIST_KEY
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
  subscriptionUserDetail: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_SUBSCRIPTION_USER_DETAIL
      ),
      Config.GET_METHOD,
      params,
      "application/json"
    );
  },
  subscriptionUserCreate: function (succes_cb, failed_cb, progress_cb, params) {
    // Screen progress set to true
    progress_cb(true);
    // http request
    HttpBrowserRequest.fetch(
      function (error, response, status) {
        // Callback function
        progress_cb(false);
        if (error) {
          return failed_cb("Something went wrong");
        }
        if (response) {
          if (response.success) {
            return succes_cb(response);
          }
          return failed_cb(response.err_l);
        }
        return failed_cb("Something went wrong");
      },
      _api_function.constructUrn(
        Config.API_ENDPOINTS.API_URN_SUBSCRIPTION_USER_CREATE
      ),
      Config.POST_METHOD,
      params,
      "application/json"
    );
  },
};

export default Core;

//////////////////////////// Public Functions END //////////////////////////////

/////////////////////////// Private Functions START ////////////////////////////
const _api_function = {
  constructUrn: function (end_point) {
    return Config.BASE_URL + end_point;
  },
};

//////////////////////////// Private Functions END /////////////////////////////
